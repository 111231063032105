// Copied from locations.cox.net repo

export class ClientBreakpointScript {
  constructor () {
      this.clientScript();
  }

  clientScript() {
      if (window.matchMedia && window.matchMedia('all').addListener) {
          var respDesktopCheck = window.matchMedia("(min-width: 941px)");
          var respTabletCheck = window.matchMedia("(max-width: 940px) and (min-width: 768px)");
          var respMobileCheck = window.matchMedia("(max-width: 767px)");

          // Set responsiveDisplayType based on media queries.
          if (respDesktopCheck.matches) {
              utag_data.responsiveDisplayType = "desktop";
          }

          if (respTabletCheck.matches) {
              utag_data.responsiveDisplayType = "tablet";
          }

          if (respMobileCheck.matches) {
              utag_data.responsiveDisplayType = "mobile";
          }

      }
  }
}
