// Copied from locations.cox.net repo

class AnswersTealiumAnalytics {
  constructor() {
    this.importedTealium = false;
  }

  getPatchData() {
    const params = new URLSearchParams(window.location.search);
    const query = params.get('query');
    const patch = {
      searchResults: document.querySelectorAll('.yxt-Card-child').length.toString(),
      searchTerm: query ? query : "",
    }
    return patch;
  }

  patchData(patch) {
    window.utag_data = window.utag_data || {};
    Object.assign(window.utag_data, patch);
  }

  importTealium() {
    this.patchData(this.getPatchData());
    const tealiumScript = document.createElement('script');
    tealiumScript.src = '//tags.tiqcdn.com/utag/cox/cbprimary/prod/utag.js';
    tealiumScript.type = 'text/javascript';
    document.body.appendChild(tealiumScript);
    this.importedTealium = true;
  }

  fireTealium(component) {
    const patch = this.getPatchData();
    this.patchData(patch);
    if (component._state._state.searchState == "search-complete") {
      if (!this.importedTealium) {
        this.importTealium();
        return;
      }

      if (window.utag_data && window.utag && typeof window.utag.link === 'function') {
        window.utag.link(patch);
      }
    }
  }
}

export {
  AnswersTealiumAnalytics
}
